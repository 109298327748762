'use client';
import useLabCart from '@labServices/context/CartContext';
import { get } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import useCart from './CartProvider';

const StickyCartContext = createContext<any>(null);

export const StickyCartProvider = ({ children }: { children: React.ReactNode }) => {
	const { cartData } = useLabCart();
	const { carts } = useCart();
	const [totalAmount, setTotalAmount] = useState(0);
	const [itemCount, setItemCount] = useState(0);
	const stickyCartDetails = {
		total: totalAmount,
		count: itemCount
	};
	useEffect(() => {
		const medicineTotal = get(carts, 'po_payable_total', '0');
		const medicineCount = get(carts, 'products.length', '0');
		const labCount = get(cartData, 'itemCount', '0');
		const labTotal = cartData?.isHardCopyRequired
			? get(cartData, 'totalAmount', '0')
			: get(cartData, 'totalAmountWithoutConveyance', '0');
		setTotalAmount(parseFloat(medicineTotal) + parseFloat(labTotal));
		setItemCount(parseInt(medicineCount) + parseInt(labCount));
	}, [cartData, carts]);

	return (
		<StickyCartContext.Provider
			value={{
				stickyCartDetails
			}}>
			{children}
		</StickyCartContext.Provider>
	);
};

const useStickyCartContext = () => {
	const context = useContext(StickyCartContext);
	if (!context) {
		throw new Error('useStickyCartContext must be used within StickyCartProvider');
	}
	return context;
};

export default useStickyCartContext;
