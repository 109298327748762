'use client';
import React, { Dispatch, createContext, useContext, useReducer, useState } from 'react';
import { LOGIN, LOGOUT, MODAL_CLOSE, MODAL_OPEN, SET_CART_ITEMS_PRICE } from './action-types';

type StateType = {
	isLoggedIn: boolean;
	isModalOpen: boolean;
	open?: boolean;
	setOpen?: (open: boolean) => void;
	cartTotal_Price_Items?: any;
};

type ActionType = {
	type: string;
	payload: any;
};

const initialState: StateType = {
	isLoggedIn: false,
	isModalOpen: false
};

const reducer = (state: StateType, action: ActionType) => {
	switch (action.type) {
		case LOGIN:
			return { ...state, isLoggedIn: true };
		case LOGOUT:
			return { ...state, isLoggedIn: false };
		case MODAL_OPEN:
			return { ...state, isModalOpen: true };
		case MODAL_CLOSE:
			return { ...state, isModalOpen: false };
		case SET_CART_ITEMS_PRICE:
			return { ...state, cartTotal_Price_Items: action.payload };

		default:
			return state;
	}
};

export const AuthContext = createContext<{
	state: StateType;
	dispatch: Dispatch<ActionType>;
	[key: string]: any;
}>({ state: initialState, dispatch: () => null });

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [open, setOpen] = useState(false);
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [cartTotalPriceItems, setcartTotalPriceItems] = useState({
		totalAmount: 0,
		itemCount: 0
	});
	const [userData, setUserData] = useState({
		defaultAddress: '',
		selectedAddress: {
			id: ''
		}
	});

	return (
		<AuthContext.Provider
			value={{
				state,
				dispatch,
				open,
				setOpen,
				cartTotalPriceItems,
				setcartTotalPriceItems,
				showCheckoutModal,
				setShowCheckoutModal,
				userData,
				setUserData
			}}>
			{children}
			{/* <CartContextProvider>{children}</CartContextProvider> */}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext(AuthContext);
