import { request } from '../arogga-api';

export const getTestsCart = () => {
	return request({
		endpoint: 'lab-order/api/v1/carts/my',
		method: 'GET'
	});
};

export const updateHardCopyTestCart = (isHardCopyRequired: boolean) => {
	return request({
		endpoint: `lab-order/api/v1/carts/my/hard-copy`,
		method: 'PUT',
		body: {
			isHardCopyRequired
		}
	});
};
export const applyDiscountCoupon = (name: string = '') => {
	return request({
		endpoint: `lab-order/api/v1/carts/my/coupon`,
		method: 'PUT',
		body: {
			name
		}
	});
};

export const addTestToCart = (labItemUqid: string, patientCount: number) => {
	return request({
		endpoint: 'lab-order/api/v1/carts/my/items',
		method: 'POST',
		body: {
			labItemUqid,
			patientCount
		}
	});
};

export const deleteTestCartItem = (cartItemUqid: string) => {
	return request({
		endpoint: `lab-order/api/v1/carts/my/lab-items/${cartItemUqid}`,
		method: 'DELETE'
	});
};

export const updateTestCartItem = (cartItemUqid: string, patientCount: number) => {
	return request({
		endpoint: `lab-order/api/v1/carts/my/lab-items/${cartItemUqid}`,
		method: 'PUT',
		body: {
			patientCount
		}
	});
};

export const updateCartAddress = (userLocationId: string | number, locationId: string | number) => {
	return request({
		endpoint: `lab-order/api/v1/carts/my/location`,
		method: 'PUT',
		body: {
			userLocationId,
			locationId
		}
	});
};

export const getCheckoutData = () => {
	return request({
		endpoint: `lab-order/api/v1/carts/my/checkout`,
		method: 'GET'
	});
};
