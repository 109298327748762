import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"display\":\"fallback\",\"preload\":true}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["AlertProvider"] */ "/app/src/contexts/AlertProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/contexts/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/app/src/contexts/CartProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginModelProvider"] */ "/app/src/contexts/LoginModelProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductProvider"] */ "/app/src/contexts/ProductProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StickyCartProvider"] */ "/app/src/contexts/StickyCartProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/services/css/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["AgoraSignalingProvider"] */ "/app/src/services/doc-consultation/context/AgoraSignalingContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartContextProvider"] */ "/app/src/services/lab-test/context/CartContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/app/src/services/lab-test/context/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/services/scss/globals.scss");
